import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import Grid from '@mui/material/Grid';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Typography from '@mui/material/Typography';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import UpdateIcon from '@mui/icons-material/Update';
import RestoreIcon from '@mui/icons-material/Restore';
import LiveTvIcon from '@mui/icons-material/LiveTv';
import ImageIcon from '@mui/icons-material/Image';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import { Select, MenuItem } from '@mui/material';

import Content from "./Content/Content";
import { request } from "../api";
import { CONTENT_URL } from "../api/urls";

export const Feed = () => {
  const theme = useTheme();
  const [url, setUrl] = useState(CONTENT_URL);

  const [content, setContent] = useState([]);
  const [filter, setFilter] = useState(null);
  const [order, setOrder] = useState(null);

  const [nextUrl, setNextUrl] = useState(null);
  const [isBottom, setIsBottom] = useState(false);
  const [keepScrolling, setKeepScrolling] = useState(true);
  const [selectedYear, setSelectedYear] = useState('');
  const [selectedMonth, setSelectedMonth] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0);
    const queryParams = new URLSearchParams();
    if (filter) {
      queryParams.set("kind", filter);
    }
    if (order) {
      queryParams.set("ordering", order);
    }
    if (selectedYear) {
      queryParams.set("year", selectedYear);
    }
    if (selectedMonth) {
      queryParams.set("month", selectedMonth);
    }
    const newUrl = `${CONTENT_URL}?${queryParams.toString()}`;
    setUrl(newUrl);
  }, [filter, order, selectedYear, selectedMonth]);

  async function fetchContent(url, append = true) {
    const response = await request(url);
    const { results, next } = response;
    
    if (!append) {
      setContent(results);
    } else {
      setContent(prevContent => [...prevContent, ...results]);
    }
    setIsBottom(false);

    if (!next) {
      setKeepScrolling(false);
    }
    setNextUrl(next);
  }

  useEffect(() => {
    fetchContent(url, false);
  }, [url]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  function handleScroll() {
    const scrollTop = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;
    const scrollHeight = (document.documentElement && document.documentElement.scrollHeight) || document.body.scrollHeight;
    if (scrollTop + window.innerHeight + 50 >= scrollHeight) {
      setIsBottom(true);
    }
  }

  useEffect(() => {
    if (isBottom && keepScrolling && nextUrl) {
      fetchContent(nextUrl);
    }
  }, [isBottom, keepScrolling, nextUrl]);

  const handleFilterChange = (event, newFilter) => {
    setFilter(newFilter);
  };

  const handleOrderChange = (event, newOrder) => {
    setOrder(newOrder);
  };

  const handleYearChange = (event) => {
    const year = event.target.value;
    setSelectedYear(year);
  };

  const handleMonthChange = (event) => {
    const month = event.target.value;
    setSelectedMonth(month);
  };

  return (
    <Grid container spacing={2}>
      <Grid item md={4} xs={6}>
        <ToggleButtonGroup
          value={order}
          exclusive
          onChange={handleOrderChange}
          aria-label="content-sorter"
          sx={{ background: '#FFF' }}
        >
          <ToggleButton value="-likes" aria-label="most popular">
            <Typography variant="h6">
              <WhatshotIcon sx={{ color: theme.palette.text.primary }} />
            </Typography>
          </ToggleButton>

          <ToggleButton value="create_date" aria-label="earliest">
            <Typography variant="h6">
              <UpdateIcon />
            </Typography>
          </ToggleButton>

          <ToggleButton value="-create_date" aria-label="most recent">
            <Typography variant="h6">
              <RestoreIcon />
            </Typography>
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>
      <Grid item md={4} xs={6}>
        <ToggleButtonGroup
          value={filter}
          exclusive
          onChange={handleFilterChange}
          aria-label="content-filter"
          sx={{float: 'right', background: '#FFF'}}
        >
          <ToggleButton value="1" aria-label="text">
            <Typography variant="h6">
              <MenuBookIcon />
            </Typography>
          </ToggleButton>

          <ToggleButton value="3" aria-label="videos">
            <Typography variant="h6">
              <LiveTvIcon />
            </Typography>
          </ToggleButton>

          <ToggleButton value="2" aria-label="images">
            <Typography variant="h6">
              <ImageIcon />
            </Typography>
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>
      <Grid item md={2} xs={6}>
        {/* Month Filter */}
        <Select
          value={selectedMonth}
          onChange={handleMonthChange}
          displayEmpty
          fullWidth
          sx={{ background: '#FFF' }}
        >
          <MenuItem value="" disabled>
            Select Month
          </MenuItem>
          {Array.from({ length: 12 }, (_, i) => i + 1).map((month) => (
            <MenuItem key={month} value={month}>
              {new Date(0, month - 1).toLocaleString('default', { month: 'long' })}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item md={2} xs={6}>  
        {/* Year Filter */}
        <Select
          value={selectedYear}
          onChange={handleYearChange}
          displayEmpty
          fullWidth
          sx={{ background: '#FFF' }}
        >
          <MenuItem value="" disabled>
            Select Year
          </MenuItem>
          {[...Array(2024 - 2014).keys()].map((year) => (
            <MenuItem key={year} value={2015 + year}>
              {2015 + year}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      
      <Grid item xs={12}>

        {content.length ? content.map((c) => (
          <Content key={c.id} content={c} />
        )) : <Grid container spacing={1} style={{ padding: "32px", height: "calc(100vh - 194px"}}>
          <Typography variant="h4" color={theme.palette.primary.main}>
            No content found
          </Typography>
          </Grid>}
      </Grid>
    </Grid>
  );
};
