import * as React from 'react';

import { useTheme } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import FavoriteIcon from '@mui/icons-material/Favorite';

import { FormattedTime } from '../Common';

export default function Content(props) {
  const { content } = props;
  const theme = useTheme();

  return (
    <Paper elevation={1} sx={{ padding: theme.spacing(2), marginBottom: theme.spacing(2)}}>
      <Grid container spacing={1}>
        <Grid item xs={10}>
          <Typography gutterBottom color="textSecondary">
            #{content.index}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="p">
            <FavoriteIcon /> {content.likes}
          </Typography>
        </Grid>
      </Grid>

      {content.kind === 2 && (
        <img
          style={{ width: 300 }}
          src={content.upload}
          alt={content.text}
        />
      )}
      
      {content.kind === 3 && (
        <video
          style={{ width: 300 }}
          src={content.upload}
          controls
        />
      )}

      <Typography gutterBottom variant="h5" component="div">
        {content.text}
      </Typography>
      {content.description && (
        <Typography color="textSecondary">
          {content.description}
        </Typography>
      )}

      <Typography color="textSecondary">
        {content.creator_nickname}, {FormattedTime(content.create_date)}
      </Typography>     
    </Paper>
  );
}
